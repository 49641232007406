<template>
  <div class="MissingUnitsModal">
    <span class="Title">{{ mixWB('DID_YOU_REMEMBER') }}...</span>

    <div class="UnitList">
      <MissingUnitsModalItem
        v-for="unit in selectedUnits"
        :key="unit.id"
        :unit="unit"
        :unitMissingCategoryIDs="missingCategoryIDs[unit.id]" />
    </div>

    <div class="ButtonsWrap">
      <Button
        :text="mixWB('BACK')"
        outlined
        @button-click="onBackClick" />
      <Button
        :text="mixWB('COMPLETE_SCREENING')"
        @button-click="onCompleteScreeningClick" />
    </div>
  </div>
</template>

<script>
import MissingUnitsModalItem from '@/components/ScreeningItems/MissingUnitsModalItem'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MissingUnitsModal',
  props: {
    missingCategoryIDs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
  },
  methods: {
    onBackClick() {
      this.$emit('pass-to-parent', { name: 'back-click' })
    },
    onCompleteScreeningClick() {
      this.$emit('pass-to-parent', { name: 'complete-screening-click' })
    },
  },
  components: {
    MissingUnitsModalItem,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .MissingUnitsModal
    display block

  .Title
    modalTitle()

  .UnitList
    margin-bottom 30px

  .ButtonsWrap
    > div
      margin-bottom 10px
</style>
