<template>
  <div class="MissingUnitsModalItem">
    <div class="Top">
      <div
        v-if="unitMissingCategoryIDs.length"
        class="IconWrap Warning">
        <WarningIcon />
      </div>
      <div
        v-else
        class="IconWrap Done">
        <CheckIcon />
      </div>
      <div class="TextWrap">
        <span class="UnitTitle">{{ unit.getTitle() }}</span>
        <template v-if="unitMissingCategoryIDs.length">
          <span class="Status">{{ mixWB('NOT_DECIDED') }}:</span>
          <span class="CategoryList">{{ getMissingCategoryNames() }}</span>
        </template>
        <span
          v-else
          class="Status">{{ mixWB('ALL_CATEGORIRES_ARE_CLEAR') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import { mapGetters } from 'vuex'

export default {
  name: 'MissingUnitsModalItem',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    unitMissingCategoryIDs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'categories',
    ]),
  },
  methods: {
    getMissingCategoryNames() {
      const categoriesAsObject = this.categories.reduce((prev, category) => {
        prev[category.id] = {
          wordbook: category.wordbook,
        }
        return prev
      }, {})
      return this.mixGetListAsText({
        idList: this.unitMissingCategoryIDs,
        objectWithAllIDs: categoriesAsObject,
        includeAnd: true,
      })
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .MissingUnitsModalItem
    margin-bottom 20px
    span
      display block

  .Top
    display flex
    align-items flex-start
    .IconWrap
      margin-right 10px
      circle(40px)
      padding 8px
      flex-shrink 0
      svg
          fill #fff
      &.Warning
        background-color $color_yellow_dark
        padding 6px 8px 10px
      &.Done
        background-color $color_primary
    .TextWrap
      padding-top 2px
      .UnitTitle
        text-transform uppercase
      .Status
        font-size 0.875rem
        color $color_grey
      .CategoryList
        font-size 0.875rem
        color $color_grey
</style>
