<template>
  <div class="NextStepButton">
    <span
      v-if="title"
      class="SectionTitle">{{ title }}</span>
    <Button
      :text="nextStep.text"
      :isDisabled="isDisabled"
      :isLoading="isButtonLoading"
      @button-click="onContinueButtonClick()" />

    <Dialog
      :isShowing="showMissingUnitsModal"
      :useComponent="MissingUnitsModal"
      :componentProps="{
        missingCategoryIDs,
      }"
      @close="showMissingUnitsModal=false"
      @back-click="showMissingUnitsModal=false"
      @complete-screening-click="onCompleteScreeningClick" />
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import MissingUnitsModal from '@/components/ScreeningItems/MissingUnitsModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'NextStepButton',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      MissingUnitsModal,
      isButtonLoading: false,
      showMissingUnitsModal: false,
      missingCategoryIDs: [],
    }
  },
  computed: {
    ...mapGetters([
      'nextStep',
      'selectedUnits',
      'categories',
      'screeningTypes',
      'skippedCategories',
    ]),
  },
  methods: {
    onContinueButtonClick() {
      if (this.nextStep.action === 'complete-unit') {
        this.$router.push(this.nextStep.path)
        this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', null)
        return
      }
      if (this.nextStep.action === 'complete-screening') {
        const allUnitsDone = this.checkAllUnits()
        if (!allUnitsDone) {
          return
        }

        this.onCompleteScreeningClick()
        return
      }

      this.$router.push(this.nextStep.path)
    },
    onCompleteScreeningClick() {
      this.showMissingUnitsModal = false
      this.isButtonLoading = true

      this.$store.dispatch('updateScreening', { setAsCompleted: true })
      setTimeout(() => {
        this.$router.push(this.nextStep.path)
      }, 200)
    },
    checkAllUnits() {
      if (this.selectedUnits.length === 1) {
        return true
      }

      const status = this.selectedUnits.reduce((prev, unit) => {
        const missingCategoryIDs = this.categories.reduce((prev2, category) => {
          // Find types for this category
          let types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id
            && x.unitIDs.includes(unit.id),
          )

          // Find types for connected categories
          if (category.connection) {
            const connectedTypes = this.screeningTypes.filter(
              (x) => category.connection.categoryIDs.includes(x.categoryID)
              && category.connection.categoryTypeIDs.includes(x.type.typeID)
              && x.unitIDs.includes(unit.id),
            )
            types = types.concat(connectedTypes)
          }

          // Check for skipped
          let isSkipped = this.skippedCategories.general.includes(category.id)
          if (!isSkipped) {
            isSkipped = this.skippedCategories[
              unit.id
            ].includes(category.id)
          }

          if (!types.length && !isSkipped) {
            prev2.push(category.id)
          }

          return prev2
        }, [])

        if (missingCategoryIDs.length) {
          prev.allGood = false
        }

        prev.missingCategoryIDs[unit.id] = missingCategoryIDs

        return prev
      }, {
        allGood: true,
        missingCategoryIDs: {},
      })

      if (!status.allGood) {
        this.missingCategoryIDs = status.missingCategoryIDs
        this.showMissingUnitsModal = true
      }

      return status.allGood
    },
  },
  components: {
    Button,
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .NextStepButton
    display block

  .SectionTitle
    user-select none
    display block
    margin-bottom 10px
    font-weight bold
    text-align center
    text-transform uppercase
</style>
