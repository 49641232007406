<template>
  <div
    v-if="imageUploadFolder"
    class="ScreeningsUpdateProfileImages">
    <div class="TitleWrap">
      <span class="PageTitle">{{ mixWB('PROFILE_IMAGES') }}</span>
      <span
        v-if="currentScreeningSelectedFilterUnitID"
        class="UnitFilterText">{{ mixWB('YOU_ONLY_SEE_FOR_ONE_UNIT') }}</span>
    </div>

    <CloseButton />

    <!-- Address images -->
    <div
      v-if="!currentScreeningSelectedFilterUnitID"
      class="ImageUploadWrap">
      <ImageUploadForm
        :basePath="getAddressBasePath()"
        :minSize="1200"
        :place="mixWB('PROFILE_IMAGE_OF_THE_ADDRESS')"
        :requiredNumber="1"
        :title="mixWB('THE_ADDRESS')"
        makeSquared
        groupName="overview"
        @image-list-update="onImageListUpdate" />
    </div>

    <!-- Units images -->
    <div
      class="ImageUploadWrap"
      :class="{
        ProfileImageSkipped: unit.hasProfileImageSkipped()
      }"
      v-for="unit in mutatedSelectedUnits"
      :key="unit.id">
      <ImageUploadForm
        :place="unit.getFullTitle()"
        :requiredNumber="1"
        :title="unit.getFullTitle()"
        makeSquared
        :basePath="getUnitBasePath(unit.id)"
        :groupName="unit.id"
        @image-list-update="onImageListUpdate">

        <div
          v-if="unit.hasProfileImageSkipped()"
          class="SkippedText">
          ✓ {{ mixWB('SKIPPED') }}
        </div>
        <Button
          class="SkipButton"
          v-if="unit.canHaveProfileImageSkipped()"
          outlined
          :fullWidth="false"
          :text="!unit.hasProfileImageSkipped() ? mixWB('SKIP') : mixWB('UPLOAD_PROFILE_IMAGE')"
          @button-click="onProfileImageSkippedClick(unit.id)" />
      </ImageUploadForm>
    </div>

    <NextStepButton
      :title="mixWB('NEXT')"
      :isDisabled="!profileImageStatus.isCompleted" />

    <div
      class="CadastralMapImageWrap"
      v-if="cadastralMapImage">
      <span class="Title">{{ mixWB('CADASTRAL_MAP') }}</span>
      <MultiImage :image="cadastralMapImage" />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import NextStepButton from '@/components/ScreeningItems/NextStepButton.vue'
import ImageUploadForm from '@/components/Forms/ImageUploadForm.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import MultiImage from '@/components/Images/MultiImage.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'ScreeningsUpdateProfileImages',
  computed: {
    ...mapGetters([
      'addressImages',
      'selectedUnits',
      'profileImageStatus',
      'imageUploadFolder',
      'currentScreeningSelectedFilterUnitID',
    ]),
    mutatedSelectedUnits() {
      if (this.currentScreeningSelectedFilterUnitID) {
        return this.selectedUnits.filter(
          (x) => x.id === this.currentScreeningSelectedFilterUnitID,
        )
      }
      return this.selectedUnits
    },
    cadastralMapImage() {
      if (this.addressImages?.overview?.cadastralMap?.length) {
        return this.addressImages.overview.cadastralMap[
          this.addressImages.overview.cadastralMap.length - 1
        ]
      }

      return false
    },
  },
  methods: {
    getAddressBasePath() {
      return `${ this.imageUploadFolder }AddressImages/Overview/`
    },
    getUnitBasePath(unitID) {
      return `${ this.imageUploadFolder }AddressImages/Units/${ unitID }/`
    },
    setImagesOnLoad() {
      const waitForData = setInterval(() => {
        if (this.addressImages === null) {
          return
        }

        if (!this.selectedUnits.length) {
          return
        }

        clearInterval(waitForData)

        if (this.addressImages?.overview?.profile) {
          EventBus.$emit('set-images-on-load', {
            imageList: this.addressImages.overview.profile,
            groupName: 'overview',
          })
        }
        if (this.addressImages?.units) {
          this.selectedUnits.forEach((unit) => {
            const unitImages = this.addressImages.units[unit.id]

            if (unitImages && unitImages.profile) {
              EventBus.$emit('set-images-on-load', {
                imageList: unitImages.profile,
                groupName: unit.id,
              })
            }
          })
        }
      }, 100)
    },
    onImageListUpdate({ imageList, groupName: place }) {
      this.$store.dispatch('setAddressImages', {
        imageList,
        place,
        imageType: 'profile',
      })
    },
    onProfileImageSkippedClick(unitID) {
      const unit = this.selectedUnits.find((unit) => unit.id === unitID)

      unit.isProfileImageSkipped = !unit.isProfileImageSkipped
      this.$store.dispatch('saveScreeningUnits', { combinedList: [unit], updateSelectedUnits: false })
    },
  },
  components: {
    CloseButton,
    ImageUploadForm,
    NextStepButton,
    MultiImage,
    Button,
  },
  created() {
    this.setImagesOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateProfileImages
    pageWrap()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase
    .UnitFilterText
      display block
      color $color_salmon
      margin-top 5px

  .ImageUploadWrap
    margin-bottom 20px
    >>> .ImageList
      align-items flex-start

    .SkipButton
      margin-left auto
      width auto
      margin-bottom 0
    &.ProfileImageSkipped
      >>> .UploadButton
        display none

  .CadastralMapImageWrap
    margin-top 20px
    .Title
      display block
      font-size 0.875rem
      padding-bottom 2px
</style>
